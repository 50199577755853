<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <!-- Content -->
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Thêm combo</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Thêm mới để thêm combo
              </p>
            </div>
            <div>
              <router-link class="btn btn-white" :to="{name: 'MySchool', query: {tab: 'combo'}}">Danh sách combo</router-link>&nbsp;
              <button type="button" v-on:click="onSubmit"
                      class="btn btn-success ">Thêm
                mới
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="comboForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="onSubmit">
                <div class="card mb-3 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label for="name" class="form-label">Tên combo</label>
                          <input v-model="combo.name" id="name" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Nội dung</label>
                          <Editor v-model:content="combo.content"/>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Khóa học</label>
                          <multiselect
                              v-model="combo.courses"
                              mode="tags"
                              valueProp="id"
                              label="name"
                              trackBy="name"
                              placeholder="Gõ để tìm kiếm"
                              :closeOnSelect="true"
                              :filterResults="true"
                              :minChars="1"
                              :resolveOnLoad="false"
                              :delay="0"
                              :searchable="true"
                              :options="courses"
                          >
                          </multiselect>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Trạng thái</label>
                          <div>
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="statusActive" v-model="combo.status" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="statusActive">Hoạt động</label>
                              </div>
                              <div class="form-check">
                                <input type="radio" id="statusInactive" v-model="combo.status" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="statusInactive">Tạm khóa</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Hiện lên trang chủ?</label>
                          <div>
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="showHomepage1" v-model="combo.show_homepage" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="showHomepage1">Hiện</label>
                              </div>
                              <div class="form-check me-3">
                                <input type="radio" id="showHomepage0" v-model="combo.show_homepage" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="showHomepage0">Không hiện</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label" for="homepage_order">Vị trí trên trang chủ?</label>
                          <input v-model="combo.homepage_order" id="homepage_order" class="form-control" type="number"/>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Thumbnail purpose="setThumbnailCombo" :thumbnail="combo.thumbnail"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Media :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent"/>

  <Modal size="modal-md" id="createComboModal" title="Thêm combo" :message="createComboMessage" :type="createComboType"/>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<script>
import Media from "@/components/Media";
import Thumbnail from "@/components/Thumbnail";
import Editor from "@/components/Editor";
import Modal from "@/components/Modal";
import Multiselect from '@vueform/multiselect'

import ApiService from "@/core/services/api.service";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'AddCombo',
  components: {
    Media,
    Thumbnail,
    Editor,
    Multiselect,
    Modal
  },
  data() {
    return {
      combo: {
        name: "",
        content: "",
        status: 1,
        show_homepage: 0,
        homepage_order: 0,
        thumbnail_id: null,
        thumbnail: {id: 0},
        courses: [],
        courses_sync: []
      },
      courses: [],
      mediaMode: "thumbnail",
      createComboMessage: "",
      createComboType: "",
      createComboDisabled: false
    }
  },
  methods: {
    handleSetMediaEvent(media, purpose) {
      if (purpose === "setThumbnailCombo") {
        this.combo.thumbnail = media;
        this.combo.thumbnail_id = parseInt(media.id);
      }
    },
    onSubmit() {
      this.createComboDisabled = true;
      this.createComboMessage = "";
      this.createComboType = "";
      this.combo.status = parseInt(this.combo.status);
      this.combo.show_homepage = parseInt(this.combo.show_homepage);
      this.combo.homepage_order = parseInt(this.combo.homepage_order);

      let courses_sync = [];
      for (let i = 0; i < this.combo.courses.length; i++) {
        courses_sync.push({id: this.combo.courses[i], position: i});
      }
      this.combo.courses_sync = courses_sync;

      let query = `mutation($name: String!, $content: String, $status: Int, $show_homepage: Int, $homepage_order: Int, $thumbnail_id: Int, $courses_sync: [SyncCourseCombo]) {
        createCombo(input: {
          name: $name
          content: $content
          status: $status
          show_homepage: $show_homepage
          homepage_order: $homepage_order
          courses: {
            sync: $courses_sync
          }
          thumbnail_id: $thumbnail_id}) {
            id
          }
      }`;

      ApiService.graphql(query, this.combo)
          .then(({data}) => {
            this.createComboDisabled = false;
            if (data.data && data.data.createCombo) {
              this.createComboMessage = "Thêm combo thành công";
              this.createComboType = "success";
              openModal("createComboModal");
              this.$router.push({name: 'MySchool', query: {tab: "combo"}});
            } else {
              this.createComboMessage = data.errors[0].message;
              this.createComboType = "danger";
              openModal("createComboModal");
            }
          })
          .catch((response) => {
            this.createComboMessage = response.message;
            this.createComboType = "danger";
            openModal("createComboModal");
          });
    },
    loadCourses() {
      let query = `query {
        mySchool {
          courses(first: 1000) {
            data {
              id
              name
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.mySchool) {
              this.courses = data.data.mySchool.courses.data;
            }
          });
    }
  },
  mounted() {
    this.loadCourses();
  }
}
</script>
